import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import useDebounce from '~/hooks/useDebounce';
import api from '~/services/api';
import { Lista } from './Lista';
import { InputContainer } from './styles';
import { InputCidadeProps, SelectType } from './protocols';

export const InputCidade: React.FC<InputCidadeProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  cidadeABuscar,
  changeSelected,
  setValue,
  clearCampo = false,
  ...rest
}) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef = useRef<HTMLInputElement | null>(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });
  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');
  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 8;

  const [resetFilter, setResetFilter] = useState<boolean>(false);

  function formataData(dataFields: any) {
    return dataFields.data.map((dt: any) => ({
      value: dt.cod_cidade,
      ufValue: dt.des_sigla,
      label: `${dt.des_cidade} - ${dt.des_sigla}`,
      cidade: dt.des_cidade,
    }));
  }

  useEffect(() => {
    if (cidadeABuscar && cidadeABuscar.cidade && cidadeABuscar.uf) {
      (async () => {
        const formattedLabel = cidadeABuscar?.cidade
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const { data } = await api.get('busca-cidade', {
          params: {
            des_uf: cidadeABuscar.uf,
            des_cidade: formattedLabel,
          },
        });
        if (data.data.length > 0) {
          const option = formataData(data);
          setSelected(option[0]);
          setValue(name, option[0]);
        }
      })();
    }
  }, [cidadeABuscar, name, setValue]);

  const fetchData = async ({ pageParam = 1 }): Promise<any> => {
    try {
      const formattedLabel = filter
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const { data: dataFields } = await api.get('busca-cidades', {
        params: {
          page: pageParam,
          limit,
          filter: formattedLabel,
        },
      });
      const options = formataData(dataFields);

      if (!options) {
        toast.error(
          'Houve um erro ao formatar os dados da busca de cidades. Favor contatar o suporte.',
        );
        return {
          count: '0',
          data: [],
          fields: [],
          pagination: {
            lastPage: 0,
            prevPage: 1,
            startPage: 1,
          },
          success: true,
        };
      }
      return {
        ...dataFields,
        data: options,
      };
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
      } else {
        toast.error(String(error));
      }
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
  };

  useEffect(() => {
    if (clearCampo) setSelected({ label: undefined, value: undefined });
  }, [clearCampo]);

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(
      `input_select_${name}`,
      ({ pageParam = 1 }) => fetchData({ pageParam }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const maxPages = lastPage.pagination.lastPage + 1;
          const nextPage = allPages.length + 1;
          return nextPage <= maxPages ? nextPage : undefined;
        },
        refetchOnWindowFocus: false,
      },
    );

  return (
    <InputContainer>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field: { value } }) => {
            const inputValue = (selected?.label || value?.label) ?? '';

            if (inputRef.current?.value === '') {
              const val = inputValue === '' ? placeholder : inputValue;

              inputRef.current.placeholder = val;
            }

            if (inputRef.current?.placeholder && value?.label === undefined) {
              inputRef.current.placeholder = placeholder;
              inputRef.current.value = filter;
            }

            return (
              <>
                <div className="select-container">
                  <label>
                    <input
                      {...register(name)}
                      type="text"
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      placeholder={placeholder || inputValue}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.target.value = event.target.value.toUpperCase();
                        setFilter(event.target.value.toUpperCase());
                        debouncedFn(() => refetch(), 500);
                        setIsOpen(true);
                      }}
                      autoComplete="off"
                      onClick={(event: any) => {
                        if (selected?.label) {
                          event.target.placeholder = selected.label;
                        }
                        event.target.value = '';
                        setIsOpen(true);
                      }}
                      ref={inputRef}
                      {...rest}
                    />
                    <div className="drop-indicator">
                      <span aria-label="open">
                        {filter && isFetching ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="spinner"
                          />
                        ) : (
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                  </label>
                </div>
                {isOpen && (
                  <Lista
                    inputRef={inputRef}
                    setIsOpen={setIsOpen}
                    setFilter={setFilter}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    data={resetFilter ? undefined : data}
                    selected={selected}
                    setSelected={setSelected}
                    isFetching={isFetching}
                    queryName={`input_select_${name}`}
                    queryClient={queryClient}
                    filter={filter}
                    changeSelected={changeSelected}
                  />
                )}
              </>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
