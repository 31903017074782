import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';

interface InputDateProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  control: Control<FieldValues, any>;
  isDisabled?: boolean;
  minDate?: string;
  maxDate?: string;
  typeError?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
}

export const InputDate: React.FC<InputDateProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  isDisabled = false,
  maxDate,
  minDate,
  typeError,
  ...rest
}) => {
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <input
                  {...register(name)}
                  type="date"
                  className={
                    isError ? 'form-control is-invalid' : 'form-control'
                  }
                  {...(typeError ? { title: String(typeError) } : {})}
                  disabled={isDisabled}
                  value={value || ''}
                  placeholder={placeholder}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  max={maxDate}
                  min={minDate}
                  {...rest}
                />
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
